import { Col, Row } from "reactstrap";
import styled from "styled-components";
import "../../scss/main.scss";

const StyledInput = styled.input`
  all: unset;
  width: 428px;
  color: #fafafa;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0px 0px 0px 20px;
  height: 40px;
  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #fafafa;
    opacity: 0.7;
  }
`;

const ConfigureInputField = (props) => {
  const { name, value, placeholder, onChange, type } = props;

  const handleInputField = (event) => {
    console.log("Event.target.value", event.target.value);
    let inputValue = onChange();
    inputValue(event.target.value);
    // return event.target.value;
  };

  return (
    <Col className="formInputField">
      <Col xs={12}>
        <Row>
          <Col xs={10}>
            <StyledInput
              name={name}
              value={value}
              autoComplete="none"
              placeholder={placeholder}
              onChange={onChange}
              type={type}
            />
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default ConfigureInputField;
