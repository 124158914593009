import Typography from "../../components/common/_typography";
import { Col, Row } from "reactstrap";

const CommonOnboardingPrgBarTitle = (props) => {
  return (
    <Row>
      <Col xs={12} className="onBoardingWokspaceTitle">
        <Typography text={props.title} tag={"head-small"} color={"#FAFAFA"} />
      </Col>
    </Row>
  );
};
export default CommonOnboardingPrgBarTitle;
