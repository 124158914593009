import { Col, Row } from "reactstrap";
import { useState, useEffect, useContext } from "react";
import Button from "../../components/common/_button";
import Typography from "../../components/common/_typography";
import InputField from "../../components/common/_loginInputField";
import OnBoardingDataSourcePipelineSetup from "../OnboardingPages/OnBoardingDataSourcePipelineSetup";
import styled from "styled-components";
import ConnectorsIcon from "../../assets/images/ConnectorsIcon.svg";
import DataBaseIcon from "../../assets/images/DataBaseIcon.svg";
import CustomCoding from "../../assets/images/customCoding.png";
import TickIcon from "../../assets/images/task_alt_white.svg";
import DeviceListToConfigure from "../../components/onBoardingComponents/_deviceListToConfigure";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import CondenseEditor from "../../components/editorComponents/CondenseEditor";
import ConnectorsCard from "../../components/connectorsComponents/_connectorsCard";
import axiosAdapter from "../../utils";
import { env } from "../../env";

const AddedNewConnectorsSkeleton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
`;

const AddedNewConnectorsCard = styled.div`
  height: 25rem;
  width: 80%;
  background: #232323;
  border-radius: 10px;
  cursor: pointer;
`;

const AddedNewConnectorsDescription = styled.div`
  background: red;
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-20%);
  }
`;

const AddedNewConnectorsDetails = (props) => {
  const {
    connectorType,
    selectedDescription,
    selectedCardIndex,
    currentIndex,
    selectedCard,
  } = props;

  let selectedIcon;
  switch (connectorType) {
    case "Connectors":
      selectedIcon = ConnectorsIcon;
      break;
    case "Databases":
      selectedIcon = DataBaseIcon;
      break;
    case "Custom":
      selectedIcon = CustomCoding;
      break;
  }

  let isHovered = currentIndex === selectedCardIndex;

  return (
    <Row>
      <Col
        xs={8}
        style={{ display: "flex", paddingTop: "1rem", paddingLeft: "2rem" }}
      >
        <img src={selectedIcon} height="60px" />
      </Col>
      {currentIndex == selectedCard && (
        <Col
          xs={4}
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <img src={TickIcon} height="30px" />
        </Col>
      )}
      <Col
        xs={12}
        style={{
          display: "flex",
          alignItems: "end",
          padding: "2rem",
          height: "20rem",
        }}
      >
        <Row>
          <Col
            xs={6}
            className={isHovered ? "selectedConnectorDescription" : ""}
          >
            <Typography
              tag="head-x-small"
              text={connectorType}
              color="#fafafa"
            />
          </Col>
          <Col
            xs={12}
            className={isHovered ? "selectedConnectorDescription" : ""}
          >
            {isHovered && (
              <Typography
                tag="p-small"
                text={selectedDescription}
                color="#fafafa"
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const ConnectorsAddedSource = (props) => {
  const { showMessage } = useContext(SnackbarContext);
  const { workspaceLayout } = props;
  const [selectedCardDescription, setSelectedCardDescription] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [multiStageStatus, setMultiStageStatus] = useState(1);
  const [headerText, setHeaderText] = useState(null);
  const [descriprionText, setDescriprionText] = useState(null);
  const [selectedCardTitle, setSelectedCardTitle] = useState(null);
  const [title, setTitle] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isSelectedSourceValid, setIsSelectedSourceValid] = useState(true);
  const [listAllMasterConnectors, setListAllMasterConnectors] = useState(null);
  const [listAllStoreConnectors, setListAllStoreConnectors] = useState(null);

  const [selectedCardId, setSelectedCardId] = useState(null);
  const [selectedCardDetails, setSelectedCardDetails] = useState({});
  const [editableNewWorkspace, setEditableNewWorkspace] = useState({});

  const listOfConnectors = ["Connectors", "Databases", "Custom"];
  console.log("selectedCardTitle", selectedCardTitle);
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSelectedSourceChange = (event) => {
    setSelectedSource(event.target.value);
  };

  const checkValidations = () => {
    const regex = new RegExp(/^(?=.{6,12}$)(?=.*[a-zA-Z])[a-zA-Z\d ]+$/);
    if (regex.test(title) == false) {
      setIsTitleValid(false);
      showMessage("Title must be 6 to 12 characters. Only 2 spaces allowed.");

      return false;
    } else if (regex.test(selectedSource) == false) {
      setIsTitleValid(true);
      setIsSelectedSourceValid(false);
      showMessage(
        "Source name must be 6 to 12  characters. Only 2 spaces allowed."
      );

      return false;
    } else {
      setIsTitleValid(true);
      setIsSelectedSourceValid(true);
      return true;
    }
  };

  const handleSelectedCard = (e, i) => {
    setSelectedCard(selectedCard === i ? null : i);
    setSelectedCardTitle(selectedCardTitle === e ? null : e);
  };

  const handleSourceSelectedContinue = (currentNumber) => {
    if (selectedCardTitle == "Custom" && multiStageStatus < 3) {
      setMultiStageStatus(3);
    } else if (selectedCardTitle == "Custom" && multiStageStatus == 3) {
      setMultiStageStatus(currentNumber + 1);
    } else if (selectedCardTitle == "Custom" && multiStageStatus == 4) {
      let validationResult = checkValidations();
      if (validationResult == true) {
        console.log("final destination of CUSTOM", title, selectedSource);
        setWorkSpaces();
      }
    } else if (selectedCardTitle != "Custom" && multiStageStatus < 2) {
      setMultiStageStatus(2);
    } else if (selectedCardTitle != "Custom" && multiStageStatus == 2) {
      console.log("final destination of NON CUSTOM");
      setWorkSpaces();
    }
    if (selectedCardTitle === "Connectors") {
      async function fetchData() {
        let listStreamConnectors = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/listAllMasterStreamConnectors"
        );
        console.log("listStreamConnectors", listStreamConnectors.data);
        if (listStreamConnectors && listStreamConnectors.data) {
          setListAllMasterConnectors(listStreamConnectors.data);
        }
      }
      fetchData();
    }

    if (selectedCardTitle === "Databases") {
      console.log("Inside the databases");
      async function fetchData() {
        let listStoreConnectors = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "admin/listAllMasterStoreConnectors"
        );
        console.log("listStreamConnectors", listStoreConnectors.data);
        if (listStoreConnectors && listStoreConnectors.data) {
          setListAllStoreConnectors(listStoreConnectors.data);
        }
      }
      fetchData();
    }
  };

  const handleAddNode = async (editableWorkspace, nodeLen, edgesLen) => {
    let lastNodePosition = {};
    // let prevId = [];
    // let lastId = editableWorkspace.node[editableWorkspace.node.length - 1];
    let updatedStringifyData = {
      ...editableWorkspace,
      node: await editableWorkspace.node.map((nodeSet) => {
        let id = JSON.parse(nodeSet.id);
        // prevId.push(nodeSet.id);
        // lastNodePosition = nodeSet.position;
        // if (id === lastId) {
        if (id === nodeLen) {
          lastNodePosition = nodeSet.position;
          return {
            ...nodeSet,
            type: "custom",
            data: {
              ...nodeSet.data,
              label: "input",
              isConnector: false,
              title: selectedCardTitle === "Custom" ? title : "Transformer",
              status: "Running",
              description: selectedCardDetails.description
                ? selectedCardDetails.description
                : "",
              sourceName:
                selectedCardTitle === "Custom"
                  ? selectedSource
                  : selectedCardDetails.name,
              connectionType: selectedCardDetails.code,
            },
          };
        }
        return nodeSet;
      }),
    };
    await updatedStringifyData.node.push({
      id: JSON.stringify(nodeLen + 1),
      type: "customDashed",
      data: {
        label: "output",
        isConnector: true,
      },
      targetPosition: "left",
      sourcePosition: "right",
      position: {
        x: lastNodePosition.x + 420,
        y: nodeLen >= 3 ? 80 : 30,
      },
    });

    await updatedStringifyData.edges.push({
      id: JSON.stringify(nodeLen + 1),
      animated: true,
      id: `reactflow__edge-${nodeLen}-${nodeLen + 1}`,
      source: `${nodeLen}`,
      sourceHandle: null,
      target: `${nodeLen + 1}`,
      targetHandle: null,
      type: "smoothstep",
    });

    console.log("updatedStringifyData---->>> After", updatedStringifyData);

    return updatedStringifyData;
  };

  const setWorkSpaces = async () => {
    setEditableNewWorkspace(workspaceLayout);
    let editableWorkspaceLayout = workspaceLayout;
    let nodesLength = editableWorkspaceLayout.node.length;
    let edgesLength = editableWorkspaceLayout.edges.length;
    let updatedNodeEdges;
    if (nodesLength > 0) {
      updatedNodeEdges = await handleAddNode(
        editableWorkspaceLayout,
        nodesLength,
        edgesLength
      );
    }

    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    let stringifyData = {
      id: selectedWorkspaceId,
      layout: {
        node: updatedNodeEdges.node,
        edges: updatedNodeEdges.edges,
      },
    };
    console.log("stringifyData", stringifyData);

    let setWorkspaceList = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/setWorkSpace",
      stringifyData
    );
    if (setWorkspaceList && setWorkspaceList.data.status == "OK") {
      props.modalAddedConnectorsFunction(false);
    }
  };

  const handleBackButton = () => {
    if (selectedCardTitle === "Custom" && multiStageStatus == 3) {
      setMultiStageStatus(1);
    } else {
      setMultiStageStatus(multiStageStatus - 1);
    }
  };

  const handleSelectedDescription = (e, i) => {
    if (e === null) {
      setSelectedCardIndex(null);
    } else {
      setSelectedCardIndex(i);
    }
    switch (e) {
      case "Connectors":
        setSelectedCardDescription("Please select a Connector");
        break;
      case "Databases":
        setSelectedCardDescription("Please select a Database");
        break;
      case "Custom":
        setSelectedCardDescription("Please write your custom code");
        break;
      default:
        setSelectedCardDescription(null);
        break;
    }
  };

  useEffect(() => {
    // console.log("selectedCardTitle--->>>>", selectedCardTitle);

    switch (multiStageStatus) {
      case 1:
        setHeaderText("Select the Source of your Data");
        setDescriprionText("Choose the data source that you wish to use");
        break;

      case 2:
        setHeaderText(`Select your ${selectedCardTitle} Model`);
        setDescriprionText(
          `Choose the ${selectedCardTitle} that you wish to use`
        );
        break;

      case 3:
        setHeaderText("Code Editor");
        setDescriprionText("");
        break;

      case 4:
        setHeaderText("Condense Code Editor");
        setDescriprionText("Fill the required information");
        break;
    }
  }, [multiStageStatus]);

  const handleCardSelect = (
    id,
    name,
    manufact_name,
    devicePort,
    networkType,
    status,
    description,
    code
  ) => {
    // handleDeviceSelected(name, manufact_name, devicePort, networkType, status);
    if (id === selectedCardId) {
      setSelectedCardId(null);
    } else {
      setSelectedCardId(id);
    }
    setSelectedCardDetails({
      id: id,
      name: name,
      manufact_name: manufact_name,
      description: description,
      code: code,
    });
  };

  return (
    <Row>
      {headerText != "" && (
        <Col xs={12}>
          <Typography tag="head-x-small" text={headerText} color="#fafafa" />
        </Col>
      )}

      {descriprionText != "" && (
        <Col xs={12} style={{ paddingTop: "1rem" }}>
          <Typography tag="p-medium" text={descriprionText} color="#fafafa" />
        </Col>
      )}

      <Col xs={12} style={{ paddingTop: "5vh" }}>
        {multiStageStatus == 1 && (
          <Row>
            {listOfConnectors.map((e, i) => (
              <Col
                xs={4}
                style={{ alignItems: "center" }}
                onMouseEnter={() => handleSelectedDescription(e, i)}
                onMouseLeave={() => handleSelectedDescription(null)}
              >
                <AddedNewConnectorsSkeleton>
                  <AddedNewConnectorsCard>
                    <Col onClick={() => handleSelectedCard(e, i)}>
                      <AddedNewConnectorsDetails
                        selectedDescription={selectedCardDescription}
                        selectedCardIndex={selectedCardIndex}
                        currentIndex={i}
                        connectorType={e}
                        selectedCard={selectedCard}
                      />
                    </Col>
                  </AddedNewConnectorsCard>
                </AddedNewConnectorsSkeleton>
              </Col>
            ))}
          </Row>
        )}

        {multiStageStatus == 3 && <CondenseEditor />}

        {multiStageStatus == 4 && (
          <Row style={{ height: "28rem" }}>
            <Col xs={5} style={{}}>
              <Col style={{ padding: "0px 0px 0px 0px", width: "25rem" }}>
                <InputField
                  throwError={!isTitleValid}
                  name="title"
                  placeholder="Enter Title"
                  type="text"
                  value={title}
                  onChange={handleTitleChange}
                />
              </Col>
              <Col style={{ padding: "15px 0px 0px 0px", width: "25rem" }}>
                <InputField
                  throwError={!isSelectedSourceValid}
                  name="selectedSource"
                  placeholder="Enter Selected Source"
                  type="text"
                  value={selectedSource}
                  onChange={handleSelectedSourceChange}
                />
              </Col>
            </Col>
            <Col xs={5}>
              <Typography tag="head-x-small" text={"Preview"} color="#fafafa" />
              <Col
                style={{
                  backgroundColor: "#3c3c3c",
                  height: "180px",
                }}
              >
                <Col
                  style={{
                    margin: "10px 10px 0px 10px",
                    padding: "10px 0px 0px 0px",
                  }}
                >
                  <ConnectorsCard
                    data={{
                      title: title,
                      sourceName: selectedSource,
                      status: "Running",
                      deviceModel: "Not Applicable",
                      port: "Not Applicable",
                    }}
                  />
                </Col>
              </Col>
            </Col>
          </Row>
        )}

        {multiStageStatus === 2 &&
          selectedCard !== 2 &&
          selectedCardTitle === "Connectors" && (
            <Row>
              {listAllMasterConnectors &&
                listAllMasterConnectors.map((e) => (
                  <Col xs={6} md={4} lg={2} style={{ paddingTop: "18px" }}>
                    <DeviceListToConfigure
                      name={e.name}
                      id={e.id}
                      icon_link={e.icon_link}
                      isSelected={e.id === selectedCardId}
                      description={e.description}
                      code={e.code}
                      onSelect={handleCardSelect}
                    />
                  </Col>
                ))}
            </Row>
          )}

        {multiStageStatus === 2 &&
          selectedCard !== 2 &&
          selectedCardTitle === "Databases" && (
            // <Row>
            //   {[...Array(16)].map(() => (
            //     <Col style={{ paddingTop: "10px" }}>
            //       <CommonManufacturerCards />
            //     </Col>
            //   ))}
            // </Row>
            <Row>
              {listAllStoreConnectors &&
                listAllStoreConnectors.map((e) => (
                  <Col xs={6} md={4} lg={2} style={{ paddingTop: "18px" }}>
                    <DeviceListToConfigure
                      name={e.name}
                      id={e.id}
                      icon_link={e.icon_link}
                      isSelected={e.id === selectedCardId}
                      description={e.description}
                      code={e.code}
                      onSelect={handleCardSelect}
                    />
                  </Col>
                ))}
            </Row>
          )}

        <Row>
          <Col
            xs={12}
            style={{
              display: "flex",
              justifyContent: "end",
              padding: "20px",
            }}
          >
            {multiStageStatus > 1 && (
              <Row>
                <Col style={{ paddingRight: "50px" }}>
                  <Button
                    disabled={selectedCard === null}
                    onClickEvent={handleBackButton}
                    tag={"secondary"}
                    btnText={"Back"}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Button
                  disabled={
                    (multiStageStatus == "1" && selectedCard === null) ||
                    (multiStageStatus == "2" && selectedCardId === null)
                  }
                  onClickEvent={() =>
                    handleSourceSelectedContinue(multiStageStatus)
                  }
                  tag={"primary"}
                  btnText={"Continue"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ConnectorsAddedSource;
