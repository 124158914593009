import React, { useState, useEffect, useCallback } from "react";
import Terminal, { ColorMode } from "react-terminal-ui";
import { io } from "socket.io-client";
import { Col, Row } from "reactstrap";
import Typography from "../../components/common/_typography";
import { Button, Spinner } from "reactstrap";
import { env } from "../../env";

let socket;
const TerminalConnector = (props) => {
  const { startButton, stopButton, isModalOpen } = props;
  const [messages, setMessages] = useState([]);
  const [statusMessage, setStatusMessage] = useState("Starting....");
  console.log("statusMessage", statusMessage);

  const handleNewMessage = useCallback(
    (message) => {
      console.log(
        "messages.length",
        messages.length,
        "statusMessage",
        statusMessage
      );
      if (statusMessage != "Started" && message) {
        setStatusMessage("Started");
      }
      setMessages((prevMessages) => [...prevMessages, message]);
    },
    [statusMessage]
  );

  useEffect(() => {
    return () => {
      socket.emit("disconnectConsumer", { newEvent: "itelematic-filtered" });
    };
  }, []);

  useEffect(() => {
    socket = io(env.REACT_APP_CLIENT_ID);
    socket.emit("startConsumer", { newEvent: "itelematic-filtered" });
    socket.on("event", (newMessage) => {
      handleNewMessage(newMessage);
    });

    socket.emit("stop", (newEvent) => {
      console.log("newEvent----", newEvent);
    });
  }, []);

  useEffect(() => {
    console.log("startButton", startButton);
    console.log("stopButton", stopButton);
    if (socket) {
      if (startButton) {
        let startConsumer = socket.emit("startConsumer", {
          newEvent: "itelematic-filtered",
        });
        setStatusMessage("Starting....");

        console.log("startConsumer", startConsumer);
      }
      if (stopButton) {
        let stopConsumer = socket.emit("stopConsumer", {
          newEvent: "itelematic-filtered",
        });
        setStatusMessage("Stopped");

        console.log("stopConsumer", stopConsumer);
      }
    }
  }, [startButton, stopButton]);

  return (
    <Row>
      <Col xs={12} style={{ paddingTop: "30px", height: "300px" }}>
        <Row>
          <Col xs={12} style={{ padding: "15px" }}>
            <Button color="primary" disabled>
              <Typography
                key={statusMessage}
                text={[
                  <b style={{ color: "#8BF5FA" }}>Status : </b>,
                  statusMessage,
                ]}
                tag="p-large"
                color="#FAFAFA"
              />
            </Button>
          </Col>
        </Row>
        <Terminal
          prompt=">"
          colorMode={ColorMode.Dark}
          height="31rem"
          maxWidth="50%"
        >
          <Col xs={2} style={{ paddingTop: "50px" }}>
            {messages.map((e, index) => (
              <Col xs={4} style={{ paddingTop: "20px" }}>
                <Typography
                  key={index}
                  text={e}
                  tag="p-small"
                  color="#FAFAFA"
                />
              </Col>
            ))}
          </Col>
        </Terminal>
      </Col>
    </Row>
  );
};

export default TerminalConnector;
