import axios from "axios";
import { useEffect, useState } from "react";
import Typography from "../../components/common/_typography";
import { Col, Container, Row } from "reactstrap";
import ProfileCard from "../../components/onBoardingComponents/_profileCard";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import SkeletonLoading from "../../components/common/_skeletonLoading";

const OnboardingWorkSpaceList = (props) => {
  const [workSpaces, setWorkSpaces] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchWorkSpaces();
  }, []);

  const fetchWorkSpaces = async () => {
    setIsLoading(true);
    let fetchWorkSpacesResponse = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace"
    );
    //API Call: Validating OTP and Resetting Password.

    setWorkSpaces(fetchWorkSpacesResponse.data.data);
    setIsLoading(false);
  };

  const handleClickedWorkSpace = (workSpaceId) => {
    //Finding selected workspace
    let selectedWorkSpace = workSpaces.find(
      (eachWorkSpace) => eachWorkSpace.id === workSpaceId
    );

    props.handlingWorkSpace(workSpaceId, selectedWorkSpace.layout);
  };

  return (
    <Row className="workspaceCardsStyle">
      <Col xs={12}>
        {isLoading ? (
          <SkeletonLoading height={"50vh"} />
        ) : (
          <Row>
            {workSpaces.length > 0 &&
              workSpaces.map((eachWorkSpace) => {
                return (
                  <Col xs={12} md={6} lg={4} key={eachWorkSpace.id}>
                    <ProfileCard
                      eachWorkSpace={eachWorkSpace}
                      handleClickedWorkSpace={handleClickedWorkSpace}
                    />
                  </Col>
                );
              })}
          </Row>
        )}
      </Col>
      <br />
    </Row>
  );
};
export default OnboardingWorkSpaceList;
