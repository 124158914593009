import { useState, useContext } from "react";
import axios from "axios";
import { Col, Row } from "reactstrap";
import InputField from "../../components/common/_loginInputField";
import Typography from "../../components/common/_typography";
import Button from "../../components/common/_button";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";

const OtpValidation = (props) => {
  const { showMessage } = useContext(SnackbarContext);

  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(true);

  const handleOtpInput = (event) => {
    setOtp(event.target.value);
  };

  const checkOtpLength = () => {
    const otpRegex = new RegExp(/^[0-9]{6,6}$/);

    if (otpRegex.test(otp) == false) {
      setIsOtpValid(false);
      showMessage("Please enter valid OTP");
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitClick = async () => {
    setIsOtpValid(true);

    //Checking OTP length
    let checkOtpResponse = await checkOtpLength();

    //If OTP length is correct, calling OTP validation API
    if (checkOtpResponse == true) {
      try {
        let config = {
          method: "POST",
          url: env.REACT_APP_URL + "admin/validateOtpAndSetCreds",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify({
            clientId: env.REACT_APP_CLIENT_ID,
            otp: otp,
            userName: props.username,
            password: props.password,
          }),
        };

        //API Call: Validating OTP and setting credentials.
        let validateOtpResponse = await axios(config);

        if (validateOtpResponse.data.code == 200) {
          props.handleClickHandler(true);
        } else {
          props.handleClickHandler(false);
        }
      } catch (error) {
        props.handleClickHandler(false);
      }
    }
  };

  const handleResendOtpClick = async () => {
    showMessage("OTP is being sent again...");

    try {
      let stringifiedData = JSON.stringify({
        clientId: env.REACT_APP_CLIENT_ID,
      });
      //API Call: Re-Sending OTP to registered email-Id for new User creation.
      let sendOtpResponse = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "admin/generateOtp",
        stringifiedData
      );

      if (sendOtpResponse.data.code == 200) {
        showMessage("Successfully resent OTP.");
      }
    } catch (error) {
      showMessage("Failed to resend OTP.");
    }
  };

  return (
    <>
      <Col xs={12} className="landingTitle">
        <Typography text={props.title} color={" #FAFAFA;"} tag={"head-small"} />
      </Col>
      <Col xs={12} sm={10} md={7} lg={12} className="landingDescription">
        <Col>
          <Typography
            text={props.description}
            color={" #B7B7B7;"}
            tag={"head-x-x-small"}
          />
        </Col>
      </Col>
      <Col xs={12} className="inputTextStyleOtp">
        <InputField
          throwError={!isOtpValid}
          placeholder={props.placeholder}
          type={props.inputType}
          value={otp}
          onChange={handleOtpInput}
        />
      </Col>

      <Col xs={12} className="resendOtpStyle" onClick={handleResendOtpClick}>
        <Col>
          <Typography
            text={[
              "Didn’t receive an OTP? ",
              <b>
                <a>
                  <u>Resend OTP</u>
                </a>
              </b>,
            ]}
            color={" #FAFAFA;"}
            tag={"p-large"}
          />
        </Col>
      </Col>
      <Col xs={12} className="otpButtonMargin">
        <Button
          disabled={otp == ""}
          tag={"large-primary"}
          btnText={"Continue"}
          onClickEvent={handleSubmitClick}
        />
      </Col>
      <Col xs={12} className="otpCancelStyle">
        <Typography
          tag={"head-x-x-small"}
          color={" #FAFAFA;"}
          text={[
            <a href="/">
              <u>Cancel</u>
            </a>,
          ]}
        ></Typography>
      </Col>
    </>
  );
};

export default OtpValidation;
