import ReactFlow, {
  addEdge,
  ConnectionLineType,
  Controls,
  applyEdgeChanges,
  applyNodeChanges,
  MiniMap,
} from "reactflow";
import React, { useCallback, useState, useContext } from "react";
import dagre from "dagre";
import axiosAdapter from "../../utils";
import { Button, Col, Row } from "reactstrap";
import ConnectorsCard from "../../components/connectorsComponents/_connectorsCard";
import ConnectorCardDashed from "../../components/connectorsComponents/_connectorsCardDashed";
import "reactflow/dist/style.css";
import { useEffect } from "react";
import { env } from "../../env";
import closeModal from "../../assets/images/closeModal.png";
import cancelModal from "../../assets/images/cancel.png";
import ConnectorsAddedSource from "./ConnectorsAddedSource";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import Typography from "../../components/common/_typography";
import TerminalConnector from "./ConnectorsTerminal";
import StyledPauseButton from "../../components/common/_pauseButton";

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));
const position = { x: 10, y: 10 };
const edgeType = "smoothstep";

let initialNodes = [];

let initialEdges = [];

const nodeWidth = 400;
const nodeHeight = 150;

const getLayoutedElements = (nodes, edges) => {
  if (nodes && edges) {
    dagreGraph.setGraph({ rankdir: "LR" });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    nodes.forEach((node, i) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = "left";
      node.sourcePosition = "right";

      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (node.position.x == 0 && node.position.y == 0) {
        node.position.x = 30;
        node.position.y = 30;
      } else if (node.position.x == 0) {
        node.position.x = 30;
      } else if (node.position.y == 0) {
        node.position.y = 30;
      }
      return node;
    });

    return { nodes, edges };
  }
};

const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
  initialNodes,
  initialEdges
);

const nodeTypes = {
  customDashed: ConnectorCardDashed,
  custom: ConnectorsCard,
};

const nodeColor = (node, e) => {
  if (node?.data?.label) {
    switch (node.data.label) {
      case "input":
        return "#6ede87";
      case "output":
        return "#6865A5";
      default:
        return "#ff0072";
    }
  }
};

const Flow = (props) => {
  const { showMessage } = useContext(SnackbarContext);
  let {
    setModalOpen,
    isModalOpen,
    isModalAddedConnectors,
    isModalTypePopover,
    isModalPopOver,
  } = props;
  const [nodes, setNodes] = useState(layoutedNodes);
  const [edges, setEdges] = useState(layoutedEdges);
  const [isSelectedNode, setSelectedNode] = useState(null);
  const [selectedNodeState, setSelectedNodeState] = useState(null);

  useEffect(() => {
    let fetchWorkSpaceIdTemp = props.fetchWorkSpaceId;
    fetchWorkSpaceIdTemp(isSelectedNode, selectedNodeState);
  }, [isSelectedNode, selectedNodeState]);

  useEffect(() => {
    let alignedWorkspaceList;
    if (
      props.workspaceLayout &&
      props.workspaceLayout.node &&
      props.workspaceLayout.edges
    ) {
      alignedWorkspaceList = getLayoutedElements(
        props.workspaceLayout.node,
        props.workspaceLayout.edges
      );
      if (
        alignedWorkspaceList &&
        alignedWorkspaceList.nodes &&
        alignedWorkspaceList.edges
      ) {
        setNodes(alignedWorkspaceList.nodes);
        setEdges(alignedWorkspaceList.edges);
      }
    }
  }, [props.workspaceLayout]);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge(
          { ...params, type: ConnectionLineType.SmoothStep, animated: true },
          eds
        )
      ),
    []
  );

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onNodeSelectedChange = (e) => {
    setSelectedNode(e.id);
    setSelectedNodeState(e.data.isConnector);
    isModalTypePopover(true);
    if (e.data.isConnector === false) {
      setModalOpen(true);
    }
    if (e.data.isConnector === true) {
      if (isModalOpen) {
        showMessage("Please close the log stream");
      }
      if (!isModalOpen) {
        isModalAddedConnectors(true);
      }
    }
  };

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      connectionLineType={ConnectionLineType.SmoothStep}
      onNodeClick={(e, i) => onNodeSelectedChange(i)}
    >
      <MiniMap nodeColor={nodeColor} />
      {props.children}
    </ReactFlow>
  );
};

const ConnectorsAddedComponents = (props) => {
  const { showMessage } = useContext(SnackbarContext);
  const [workspaceLayout, setWorkspaceLayout] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalAddedConnectors, setModalAddedConnectors] = useState(false);
  const [startButton, setStartButton] = useState(false);
  const [stopButton, setStopButton] = useState(false);
  const [isModalPopOver, setIsModalPopOver] = useState(false);
  const [selectedWorkSpace, setSelectWorkSpace] = useState(null);
  const [selectedWorkSpaceType, setSelectedWorkspaceType] = useState(null);

  async function fetchData() {
    let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
    let stringifyData = { id: selectedWorkspaceId };
    let getWorkSpace = await axiosAdapter(
      "POST",
      env.REACT_APP_URL + "pipeline/getWorkSpace",
      stringifyData
    );
    if (getWorkSpace && getWorkSpace.data && getWorkSpace.data.data) {
      setWorkspaceLayout(getWorkSpace.data.data.layout);
      return true;
    }
  }

  useEffect(() => {
    if (props.selectedWorkSpaceId) {
      localStorage.setItem("selectedWorkSpaceId", props.selectedWorkSpaceId);
    }
    fetchData();
  }, []);

  const handlePauseButton = () => {
    console.log("startButton", startButton, "stopButton", stopButton);
    if (startButton === true) {
      showMessage("Log Stream has already been running");
      setStartButton(false);
      setStopButton(false);
    } else {
      setStartButton(true);
      setStopButton(false);
    }
  };

  const handleStopButton = () => {
    if (stopButton === true) {
      showMessage("Log Stream has already been stopped");
      setStartButton(false);
      setStopButton(false);
    } else {
      setStopButton(true);
      setStartButton(false);
    }
  };

  const setModalOpenFunction = (e) => {
    setModalOpen(e);
  };

  const isModalAddedConnectorsFunction = (e) => {
    setModalAddedConnectors(e);
    fetchData();
  };

  const isModalTypePopoverFunction = () => {
    setIsModalPopOver(true);
  };

  const setPlayStop = (e) => {
    if (e === "start") {
      setStartButton(false);
    }

    if (e === "stop") {
      setStopButton(false);
    }
  };

  const handleRefetchNodes = async () => {
    let isFetchSuccess = await fetchData();
    if (isFetchSuccess) {
      showMessage("Delted the node successfully");
      setModalOpen(false);
      setIsModalPopOver(false);
    }
  };

  const setFetchWorkSpaceId = (e, i) => {
    console.log("This is e, i", e, i);
    setSelectWorkSpace(e);
    setSelectedWorkspaceType(i);
  };

  const handleDeleteNode = async () => {
    let selectedWorkspaceNodeList = [];
    let selectedWorkspaceEdgesList = [];

    let removeWorkspaceId;
    let tempWorkSpaceId = workspaceLayout;
    let selectedWorkSpaceTemp = selectedWorkSpace;
    let nodeLength = tempWorkSpaceId.node.length;
    let edgeLength = tempWorkSpaceId.edges.length;

    if (selectedWorkSpaceTemp > 1 && selectedWorkSpaceType === false) {
      for (let i = 0; i < nodeLength; i++) {
        if (tempWorkSpaceId.node[i].id !== selectedWorkSpaceTemp) {
          selectedWorkspaceNodeList.push(tempWorkSpaceId.node[i]);
        } else {
          removeWorkspaceId = tempWorkSpaceId.node[i].id;
        }
      }
      if (removeWorkspaceId) {
        for (let i = 0; i < edgeLength; i++) {
          let sourceValue = JSON.parse(tempWorkSpaceId.edges[i].source);
          let targetValue = JSON.parse(tempWorkSpaceId.edges[i].target);
          let tempSource = tempWorkSpaceId.edges[i];
          if (
            tempWorkSpaceId.edges[i].source === removeWorkspaceId &&
            sourceValue >= 1
          ) {
            tempSource.source = tempWorkSpaceId.edges[i - 1].source;
            selectedWorkspaceEdgesList.push(tempSource);
          } else if (tempWorkSpaceId.edges[i].target === removeWorkspaceId) {
            tempSource.target = tempWorkSpaceId.edges[i + 1].target;
            // selectedWorkspaceEdgesList.push(tempSource);
          } else {
            selectedWorkspaceEdgesList.push(tempWorkSpaceId.edges[i]);
          }
          console.log("tempWorkSpaceId.edges", tempWorkSpaceId.edges);
        }
      }
      let selectedWorkspaceId = localStorage.getItem("selectedWorkSpaceId");
      let stringifyData = {
        id: selectedWorkspaceId,
        layout: {
          node: selectedWorkspaceNodeList,
          edges: selectedWorkspaceEdgesList,
        },
      };
      console.log("stringifyData", stringifyData);

      let setWorkspaceList = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/setWorkSpace",
        stringifyData
      );
      if (setWorkspaceList && setWorkspaceList.data.status == "OK") {
        handleRefetchNodes();
      } else {
        showMessage("Cannot Delete This Node");
      }
    } else {
      showMessage("Cannot Delete This Node");
      setModalOpen(false);
      setIsModalPopOver(false);
      setModalAddedConnectors(false);
    }

    console.log("selectedWorkspaceNodeList", selectedWorkspaceNodeList);
    console.log("selectedWorkspaceEdgesList", selectedWorkspaceEdgesList);
  };

  const handleTerminal = () => {
    setIsModalPopOver(false);
  };

  return (
    <Col xs={12} style={{ height: "calc(100vh - 80px)" }}>
      <Flow
        workspaceLayout={workspaceLayout}
        setModalOpen={setModalOpenFunction}
        isModalAddedConnectors={isModalAddedConnectorsFunction}
        isModalTypePopover={isModalTypePopoverFunction}
        isModalOpen={isModalOpen}
        isModalPopOver={isModalPopOver}
        fetchWorkSpaceId={setFetchWorkSpaceId}
      >
        {/* {isModalPopOver && (
          <Row>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                zIndex: "900",
                backdropFilter: "blur(5px)",
              }}
            >
              <Col
                xs={6}
                className="addedNewConnectors"
                style={{ height: "15vh" }}
              >
                <Row>
                  <Col
                    xs={12}
                    style={{
                      padding: "10px 40px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Typography
                      text={"Please Choose to Continue"}
                      tag="sub-head-medium"
                      color="#FAFAFA"
                    />
                  </Col>

                  <Col
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Row>
                      <Col xs={6} style={{ padding: "10px" }}>
                        <Button
                          style={{
                            marginRight: "30px",
                          }}
                          size="lg"
                          color="danger"
                          outline
                          onClick={handleDeleteNode}
                        >
                          Delete
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} style={{ padding: "10px" }}>
                        <Button
                          size="lg"
                          color="primary"
                          onClick={handleTerminal}
                        >
                          {selectedWorkSpaceType ? "New Connector" : "Termial"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        )} */}
        {isModalOpen && !isModalAddedConnectors && (
          // TODO: Need to integrate Model for Log Streaming and Code editor
          <Row>
            <Col xs={8} className="connectorsPageModal">
              <Row>
                <Col className="connectorsPageModalCloseBtn">
                  <img
                    src={closeModal}
                    onClick={() => setModalOpenFunction(false)}
                    height="30px"
                    style={{
                      paddingTop: "15px",
                      paddingRight: "15px",
                      cursor: "pointer",
                    }}
                  />
                </Col>
                <Col xs={12} style={{ padding: "10px 30px" }}>
                  <Row>
                    <Col
                      xs={1}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Row>
                        <Col className="beaconIndicatorGreen" />
                      </Row>
                    </Col>
                    <Col
                      xs={5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        text="Input Data Source"
                        tag="head-x-small"
                        color="#FAFAFA"
                      />
                    </Col>
                    <Col
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <StyledPauseButton
                            onClick={handlePauseButton}
                            iconType={"play"}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      xs={1}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Row>
                        <Col xs={12}>
                          <StyledPauseButton
                            onClick={handleStopButton}
                            iconType={"stop"}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <TerminalConnector
                    startButton={startButton}
                    stopButton={stopButton}
                    setPlayStop={setPlayStop}
                    isModalOpen={isModalOpen}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {isModalAddedConnectors && !isModalOpen && (
          <Row>
            <Col
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
                zIndex: "900",
                backdropFilter: " blur(5px)",
              }}
            >
              <Col
                xs={10}
                className="addedNewConnectors"
                style={{ height: "85vh", overflowY: "auto" }}
              >
                <Row>
                  <Col xs={12} className="addedNewConnectorsCloseBtn">
                    <img
                      src={cancelModal}
                      onClick={() => setModalAddedConnectors(false)}
                      height="50px"
                      style={{
                        paddingTop: "15px",
                        paddingRight: "15px",
                        cursor: "pointer",
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col style={{ padding: "0px 40px" }}>
                    <ConnectorsAddedSource
                      workspaceLayout={workspaceLayout}
                      modalAddedConnectorsFunction={
                        isModalAddedConnectorsFunction
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        )}
      </Flow>
    </Col>
  );
};

export default ConnectorsAddedComponents;
