import React, { Suspense } from "react";
import { authProtectedRoutes, publicRoutes } from "./routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./scss/main.scss";
import "./App.css";
import AppRoute from "./routes/route";

/**
 *
 * @description (React Entry Point - Defines Public and Private Route)
 * @return {*}
 */
function App() {
  return (
    <div>
      <Router>
        <Switch>
          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={true}
            />
          ))}
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
