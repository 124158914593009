import JSIcon from "../../assets/folderIcons/js.svg";
import CssIcon from "../../assets/folderIcons/css.svg";
import HtmlIcon from "../../assets/folderIcons/html.svg";
import JsonIcon from "../../assets/folderIcons/json.svg";
import JsxIcon from "../../assets/folderIcons/jsx.svg";
import DefaultIcon from "../../assets/folderIcons/eff.svg";

export const explorer = {
  id: 1680092544000,
  name: "root",
  isFolder: true,
  items: [
    {
      id: 1680092544001,
      name: "public",
      isFolder: true,
      items: [
        {
          id: 1680092544002,
          name: "public nested 1",
          isFolder: true,
          items: [
            {
              id: 1680092544003,
              name: "index.html",
              isFolder: false,
              code: `import React from "react";

const DropDownMenu = ({
  optionsStructure,
  handleNewDirectoryClicked,
  deleteFileOrFolder,
  handleRenameClicked,
}) => {
  const handleListClick = (id) => {
    if (id == 1) {
      handleNewDirectoryClicked("folder");
    } else if (id == 2) {
      handleNewDirectoryClicked("file");
    } else if (id == 3) {
      handleRenameClicked();
    } else if (id == 4) {
      deleteFileOrFolder();
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#262625",
        padding: "10px 15px 10px 15px",
        border: "1px solid #545453",
        borderRadius: "2px",
        width: "12rem",
      }}
    >
      {optionsStructure.map((option) => {
        return (
          <div
            key={option.id}
            onClick={() => handleListClick(option.id)}
            style={{ cursor: "pointer", color: "white", marginBottom: "5px" }}
          >
            {option.text}
          </div>
        );
      })}
    </div>
  );
};

export default DropDownMenu; `,
              items: [],
            },
            {
              id: 1680092544004,
              name: "hello.css",
              isFolder: false,
              code: `import Typography from "../common/_typography";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import AccountNavigator from "../common/_accountNavigator";
import moment from "moment";

/**
 *NOTE: Common Component
 * @description This Component will show Users Profile and Account creation Date.
 * @param {*} props
 * @return {*}
 */
const ProfileCard = (props) => {
  let { eachWorkSpace } = props;

  const handleWorkSpaceClick = (index) => {
    props.handleClickedWorkSpace(index);
  };

  return (
    <ProfileCardComponent
      onClick={() => handleWorkSpaceClick(eachWorkSpace.id)}
    >
      <Col xs={12} className="profileCardAvatarSkeleton">
        <Row>
          <Col xs={2} className="profileCardAvatar">
            <AccountNavigator
              iconText={eachWorkSpace.name.slice(0, 2).toUpperCase()}
              iconTextColor={"black"}
              iconTextSize={"sub-head-medium"}
            />
          </Col>
          <Col xs={10} className="profileCardContainer">
            <Col>
              <Typography
                text={eachWorkSpace.name}
                color={"#FAFAFA"}
                tag={"head-x-small"}
              />
            </Col>
          </Col>
        </Row>

        <Row className="profileCardSubTitle1">
          <Col xs={12}>
            <Typography
              tag={"sub-head-small"}
            />
          </Col>
          {/* <Col xs={12} className="profileCardSubTitle2"> */}
          {/* <Typography
                  text={"No Collabrators"}
                  color={" #909090"}
                  tag={"sub-head-small"}
                /> */}
          {/* </Col> */}
        </Row>
      </Col>
    </ProfileCardComponent>
  );
};

export default ProfileCard;
`,
              items: [],
            },
          ],
        },
        {
          id: 1680092544005,
          name: "public_nested_file.js",
          isFolder: false,
          code: `"use strict";

import dotenv from "dotenv";
dotenv.config();

const dbDetails = {
	username: process.env.DB_USERNAME,
	password: process.env.DB_PASSWORD,
	database: process.env.DB_NAME,
	host: process.env.DB_HOST,
	dialect: process.env.DB_TYPE,
	logging: false
};

module.exports = {
	development: dbDetails,
	production: dbDetails
};`,
          items: [],
        },
      ],
    },
    {
      id: 1680092544006,
      name: "src",
      isFolder: true,

      items: [
        {
          id: 1680092544007,
          name: "App.jsx",
          isFolder: false,
          code: `"use strict";

import dotenv from "dotenv";
dotenv.config();

const dbDetails = {
	username: process.env.DB_USERNAME,
	password: process.env.DB_PASSWORD,
	database: process.env.DB_NAME,
	host: process.env.DB_HOST,
	dialect: process.env.DB_TYPE,
	logging: false
};

module.exports = {
	development: dbDetails,
	production: dbDetails
};`,
          items: [],
        },
        {
          id: 1680092544008,
          name: "Index.js",
          isFolder: false,
          code: `/*
 * @Author: Ratnadeep Maurya
 * @Date: 2022-09-22 19:32:46
 */
export default function (sequelize, DataTypes) {
  return sequelize.define(
    'aggregated_bill_category_wise',
    {
      id: {
        type: DataTypes.INTEGER,
        primaryKey: true,
        autoIncrement: true,
      },
      srcId: DataTypes.STRING,
      custId: DataTypes.STRING,
      categoryId: DataTypes.STRING,
      fromDate: DataTypes.BIGINT,
      toDate: DataTypes.BIGINT,
      totalDeviceCount: DataTypes.INTEGER,
      totalNoOfPackets: DataTypes.INTEGER,
      totalNoOfData: DataTypes.BIGINT,
      billingPlanId: DataTypes.INTEGER,
      totalTcuCost: DataTypes.FLOAT,
      totalSimCost: DataTypes.FLOAT,
      createdAt: DataTypes.BIGINT,
      updatedAt: DataTypes.BIGINT,
    },
    {
      timestamps: false,
      freezeTableName: true,
    }
  );
}`,
          items: [],
        },
        {
          id: 1680092544009,
          name: "styles.css",
          isFolder: false,
          code: `/*
 * @Author: Ratnadeep Maurya
 * @Date: 2022-09-08 11:53:46
 */
export default function (sequelize, DataTypes) {
	return sequelize.define(
		"feature_master",
		{
			id: {
				type: DataTypes.INTEGER,
				primaryKey: true
			},
			category_id: DataTypes.INTEGER,
			iconPath: DataTypes.STRING,
			name: DataTypes.STRING,
			tooltip: DataTypes.STRING
		},
		{
			timestamps: false,
			freezeTableName: true
		}
	);
}
`,
          items: [],
        },
      ],
    },
    {
      id: 1680092544010,
      name: "package.json",
      isFolder: false,
      code: `/*
 * @Author: Shubham Poddar
 * @Date: 2022-08-29 04:20:46
 */
export default function (sequelize, DataTypes) {
  return sequelize.define(
    'oem_master',
    {
      id: {
        type: DataTypes.INTEGER,
        primaryKey: true,
        autoIncrement: true,
      },
      oemName: DataTypes.STRING,
      oemCode: DataTypes.STRING,
      oemEmail: DataTypes.STRING,
      phoneNumber: DataTypes.STRING,
      addressLine1: DataTypes.STRING,
      addressLine2: DataTypes.STRING,
      bankName: DataTypes.STRING,
      accHoldingPerson: DataTypes.STRING,
      accNumber: DataTypes.STRING,
      ifsc: DataTypes.STRING,
      gst: DataTypes.STRING,
      pan: DataTypes.STRING,
      countryId: DataTypes.INTEGER,
      countryName: DataTypes.STRING,
      stateId: DataTypes.INTEGER,
      stateName: DataTypes.STRING,
      city: DataTypes.INTEGER,
      cityName: DataTypes.STRING,
      pincode: DataTypes.INTEGER,
      userId: DataTypes.INTEGER,
      logo: DataTypes.STRING,
      ownerId: DataTypes.INTEGER,
      otp: DataTypes.INTEGER,
      otpExpiry: DataTypes.INTEGER,
      status: DataTypes.STRING,
      softwareWallet: DataTypes.INTEGER,
      simWallet: DataTypes.INTEGER,
      endPointUrl: DataTypes.STRING,
      gstRegType: DataTypes.STRING,
      industry: DataTypes.STRING,
      tallyCustomerId: DataTypes.INTEGER,
      ledOnaccountDate: DataTypes.STRING,
      ledOnaccountType: DataTypes.STRING,
      ledOnaccountAmt: DataTypes.INTEGER,
      oemStatus: DataTypes.STRING,
    },
    {
      timestamps: false,
      freezeTableName: true,
    }
  );
}
`,
      items: [],
    },
  ],
};

export const selectIcon = (iconName) => {
  switch (iconName) {
    case "js":
      return JSIcon;
      break;
    case "html":
      return HtmlIcon;
      break;
    case "css":
      return CssIcon;
      break;
    case "json":
      return JsonIcon;
      break;
    case "jsx":
      return JsxIcon;
      break;
    default:
      return DefaultIcon;
  }
};
