import { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import AccountNavigator from "../common/_accountNavigator";
import expandIcon from "../../assets/images/expandDropDown.png";
import AccountDropDown from "../common/_accountDropDown";

const NavBar = (props) => {
  const [iconText, setIconText] = useState("");
  const [isAccountDropDown, setAccountDropDown] = useState(false);

  useEffect(() => {
    const iconTextFromLocalStorage = localStorage.getItem("userName");

    if (iconTextFromLocalStorage.length > 2) {
      setIconText(iconTextFromLocalStorage.slice(0, 2).toUpperCase());
    } else {
      setIconText("AA");
    }
  }, []);

  const accountDropDown = () => {
    setAccountDropDown(!isAccountDropDown);
  };

  const handleThemeToggler = () => {
    const body = document.querySelector("body");
    if (body.classList.contains("light-theme")) {
      body.classList.remove("light-theme");
    } else {
      body.classList.add("light-theme");
    }
  };
  return (
    <Container fluid className="navBarStyle">
      <Row>
        <Col xs={12} style={{ paddingTop: "15px" }}>
          <Row>
            <Col xs={9}>
              <img
                src={condenseLogo}
                width="205px"
                height="46px"
                alt="condenseLogo"
              />
            </Col>
            {/* <Col xs={1} className="navBarThemeToggleButtonText">
              <Typography
                text={"Dark Mode"}
                color={"#FAFAFA"}
                tag={"p-medium"}
              />
            </Col>
            <Col xs={1} className="navBarThemeColToggleButton">
              <ToggleButton themeToggler={handleThemeToggler} />
            </Col> */}
            <Col xs={3} className="navBarThemeColAccountNavigator">
              <AccountNavigator
                iconText={iconText}
                iconTextColor={"black"}
                iconTextSize={"sub-head-medium"}
              />
              <img
                src={expandIcon}
                onClick={accountDropDown}
                alt="expandIcon"
                width="24px"
                height="24px"
                style={{cursor:'pointer'}}
              />
            </Col>
          </Row>
        </Col>
        {isAccountDropDown && (
          <Col xs={12} className="navBarAccountDropDown">
            <Row>
              <Col xs={12}>
                <AccountDropDown />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Container>
  );
};
export default NavBar;
