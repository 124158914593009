import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import Editor from "@monaco-editor/react";
import { explorer } from "./folderData";
import Folder from "./Folder";

const CondenseEditor = () => {
  const [explorerData, setExplorerData] = useState(explorer);
  const [tempExplorerData, setTempExplorerData] = useState(explorer);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [editorValueToShow, setEditorValueToShow] = useState("");
  const [selectedFileId, setSelectedFileId] = useState("");
  const [tabs, setTabs] = useState([]);
  const [isTabHover, setIsTabHover] = useState(false);

  const handleMouseOver = () => {
    setIsTabHover(true);
  };

  const handleMouseOut = () => {
    setIsTabHover(false);
  };

  const handleTabClick = async (tab) => {
    setSelectedFileId(tab.id);

    await clearEditorValue();
    for (let object of tabs) {
      if (object.id == tab.id) {
        object.isActivated = true;
      } else {
        delete object.isActivated;
      }
    }

    setEditorValueToShow(tab.code);
  };

  const handleTabClose = async (id) => {
    let updatedTabs = tabs.filter((item) => item.id !== id);

    await setTabs(updatedTabs);
    await updateTabsArray(
      updatedTabs[0].id,
      updatedTabs[0].name,
      updatedTabs[0].code
    );
  };

  const clearEditorValue = () => {
    setEditorValueToShow("");
  };

  const updateTabsArray = async (
    selectedFileId,
    selectedFileName,
    selectedFileCode
  ) => {
    await clearEditorValue();
    setSelectedFileId(selectedFileId);
    const found = tabs.find((element) => element.id == selectedFileId);

    if (found == undefined) {
      setTabs([
        ...tabs,
        {
          id: selectedFileId,
          name: selectedFileName,
          code: selectedFileCode,
          isActivated: true,
        },
      ]);

      setEditorValueToShow(selectedFileCode);

      if (tabs.length > 0) {
        for (let object of tabs) {
          if (object.id == selectedFileId) {
            object.isActivated = true;
          } else {
            delete object.isActivated;
          }
        }
      }
    } else {
      setEditorValueToShow(selectedFileCode);

      for (let object of tabs) {
        if (object.id == selectedFileId) {
          object.isActivated = true;
        } else {
          delete object.isActivated;
        }
      }
    }
  };

  const handleInsertNode = (folderId, item, isFolder) => {
    const finalTree = insertNode(explorerData, folderId, item, isFolder);
    setExplorerData(finalTree);
  };

  const insertNode = function (tree, folderId, item, isFolder) {
    if (tree.id === folderId && tree.isFolder) {
      tree.items.unshift({
        id: new Date().getTime(),
        name: item,
        isFolder: isFolder,
        code: isFolder == true ? "" : "//Start writing code from here...",
        isSelected: false,
        items: [],
      });

      return tree;
    }

    let latestNode = [];
    latestNode = tree.items.map((ob) => {
      return insertNode(ob, folderId, item, isFolder);
    });

    return { ...tree, items: latestNode };
  };

  const handleDeleteNode = (finalTree, selectedId) => {
    setExplorerData(finalTree);

    let updatedTabs = tabs.filter((item) => item.id !== selectedId);

    setTabs(updatedTabs);
  };

  const handleRenameNode = (finalTree, selectedId, inputValue) => {
    setExplorerData(finalTree);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === selectedId) {
        tabs[i].name = inputValue;
        break;
      }
    }
  };

  const setButtonClick = () => {
    setIsButtonClicked(!isButtonClicked);
  };

  const updateExplorer = (id, newCode, explorerData) => {
    if (explorerData.id === id) {
      explorerData.code = newCode;
    } else if (explorerData.items) {
      explorerData.items.forEach((item) => updateExplorer(id, newCode, item));
    }
  };

  const handleEditorChange = async (value, event) => {
    // here is the current value
    // console.log("value:", value);
    // console.log("event:", event);

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].id === selectedFileId) {
        tabs[i].code = value;
        setTabs(tabs);
        break;
      }
    }

    await updateExplorer(selectedFileId, value, explorerData);
    await setExplorerData(explorerData);
  };

  const handleEditorDidMount = (editor, monaco) => {
    // console.log("onMount: the editor instance:", editor);
    // console.log("onMount: the monaco instance:", monaco);
  };

  const handleEditorWillMount = (monaco) => {
    // console.log("beforeMount: the monaco instance:", monaco);
  };

  const handleEditorValidation = (markers) => {
    // model markers
    // markers.forEach(marker => console.log('onValidate:', marker.message));
  };

  return (
    <Col xs={12} className="codeEditorMainCol">
      <Row className="codeEditorMainRow">
        <Col xs={3} className="codeEditorFolderTree">
          {(isButtonClicked == true || isButtonClicked == false) && (
            <Folder
              handleInsertNode={handleInsertNode}
              handleDeleteNode={handleDeleteNode}
              handleRenameNode={handleRenameNode}
              explorer={explorerData}
              tempExplorerData={tempExplorerData}
              setButtonClick={setButtonClick}
              updateTabsArray={updateTabsArray}
            />
          )}
        </Col>

        <Col xs={9} className="codeEditorEditorArea">
          <div>
            {tabs.length
              ? tabs.map((tab) => {
                  return (
                    <button
                      key={tab.id}
                      className={
                        tab.isActivated ? "codeEditorTabs1" : "codeEditorTabs2"
                      }
                      onClick={() => handleTabClick(tab)}
                      onMouseOver={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {tab.name}{" "}
                      <button
                        onClick={() => handleTabClose(tab.id)}
                        style={{
                          visibility: isTabHover ? "" : "hidden",
                        }}
                        className={
                          tab.isActivated
                            ? "codeEditorTabClose1"
                            : "codeEditorTabClose2"
                        }
                      >
                        x
                      </button>
                    </button>
                  );
                })
              : ""}
          </div>
          {editorValueToShow && (
            <Editor
              theme="vs-dark"
              height="56vh"
              onChange={handleEditorChange}
              onMount={handleEditorDidMount}
              beforeMount={handleEditorWillMount}
              onValidate={handleEditorValidation}
              defaultLanguage="javascript"
              options={""}
              defaultValue={editorValueToShow}
            />
          )}
        </Col>
      </Row>
    </Col>
  );
};

export default CondenseEditor;
