import { Col, Row, Container } from "reactstrap";
import { Handle, Position } from "reactflow";
import React, { memo } from "react";
import styled from "styled-components";
import Typography from "../common/_typography";
import antenna from "../../assets/images/antenna_icon.svg";

const ConnectorCardSkeleton = styled.div`
  min-width: 350px;
  height: 160px;
  background: #313131;
`;

const ConnectorsCardLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectorCardButton = styled.div`
  color: #47ccd6;
  height: 36px;
  display: flex;
  align-items: center;
  text-align: center;
  border: 2px solid #47ccd6;
`;

const ConnectorCardStatus = styled.div`
  min-width: 70px;
  height: 28px;
  background: ${(props) => props.currentStatusBackground};
  border: 2px solid ${(props) => props.currentStatusBorder};
  border-radius: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.001em;
  color: ${(props) => props.currentStatusBorder};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectorsCard = (props) => {
  const { data } = props;
  let currentStatusBackground;
  let currentStatusBorder;

  switch (data.status) {
    case "Stopped":
      currentStatusBackground = "#F69697";
      currentStatusBorder = "#FF2700";
      break;
    case "Halt":
      currentStatusBackground = "#FFF299";
      currentStatusBorder = "#CCB200";
      break;
    case "Running":
      currentStatusBackground = "#a3eac6";
      currentStatusBorder = "#27945e";
      break;
    default:
      break;
  }

  return (
    <ConnectorCardSkeleton>
      <ConnectorsCardLayout>
        <Handle type="target" position={Position.Left} />
        <Container fluid>
          <Col xs={12}>
            <Col xs={12} className="componentCardSkeletonStyle">
              <Row className="componentCardSkeletonMargin">
                <Col xs={1}>
                  <img src={antenna} height="21.4px" />
                </Col>

                <Col xs={7}>
                  <Typography
                    text={data.title}
                    tag="p-bold-large"
                    color="#FAFAFA"
                  />
                </Col>
                <Col xs={3}>
                  <ConnectorCardStatus
                    currentStatusBackground={currentStatusBackground}
                    currentStatusBorder={currentStatusBorder}
                  >
                    {data.status}
                  </ConnectorCardStatus>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Col xs={12} className="componentCardTextStyle">
                <Typography
                  text={`Node Type: ${data.sourceName}`}
                  tag="p-bold-small"
                  color="#E1E1E1"
                />
              </Col>
              {data.deviceModel && (
                <Col xs={12} className="componentCardTextStyle">
                  <Typography
                    text={`Device Model: ${data.deviceModel}`}
                    tag="p-bold-small"
                    color="#E1E1E1"
                  />
                </Col>
              )}
              {data.port && (
                <Col xs={12} className="componentCardTextStyle">
                  <Typography
                    text={`Port: ${data.port}`}
                    tag="p-bold-small"
                    color="#E1E1E1"
                  />
                </Col>
              )}
              {data.description && (
                <Col xs={12} className="componentCardTextStyle">
                  <Typography
                    text={`Description: ${data.description}`}
                    tag="p-bold-small"
                    color="#E1E1E1"
                  />
                </Col>
              )}
              {data.connectionType && (
                <Col xs={12} className="componentCardTextStyle">
                  <Typography
                    text={`Connection Type: ${data.connectionType}`}
                    tag="p-bold-small"
                    color="#E1E1E1"
                  />
                </Col>
              )}
            </Col>
            <Col xs={12}>
              <Col
                xs={8}
                style={{ padding: "20px 0px 0px 0px", textAlign: "start" }}
              >
                {/* <ConnectorCardButton>
                  <Col xs={12}>
                    <Typography text="Re-configure Source" tag="p-bold-small" />
                  </Col>
                </ConnectorCardButton> */}
              </Col>
            </Col>
          </Col>
        </Container>
        <Handle type="source" position={Position.Right} />
      </ConnectorsCardLayout>
    </ConnectorCardSkeleton>
  );
};
export default ConnectorsCard;
