import { Col, Container } from "reactstrap";
import styled from "styled-components";
import addCircle from "../../assets/images/add_circle.svg";
import Typography from "../common/_typography";
import { Handle, Position } from "reactflow";

const ConnectorCardDashedSkeleton = styled.div`
  box-sizing: border-box;
  min-width: 350px;
  height: 160px;
  border: 2px dashed #909090;
  letter-spacing: 10px;
`;

const ConnectorCardDashed = () => {
  return (
    <ConnectorCardDashedSkeleton>
      <Handle type="target" position={Position.Left} />
      <Col xs={12}>
        <Col xs={12} className="connectorCardDashedSkeleton">
          <img src={addCircle} height="40px" />
        </Col>
        <Col xs={12} className="connectorCardDashedHeight">
          <Col xs={10}>
            <Typography
              text="Connect Transform(s)/Destination"
              tag="p-bold-large"
              color="#FAFAFA"
            />
          </Col>
        </Col>
      </Col>
      <Handle type="source" position={Position.Right} />
    </ConnectorCardDashedSkeleton>
  );
};

export default ConnectorCardDashed;
