import Typography from "../common/_typography";
import { Col, Container, Row } from "reactstrap";
import styled from "styled-components";
import AccountNavigator from "../common/_accountNavigator";
import moment from "moment";

const ProfileCardComponent = styled.div`
  width: 100%;
  background: #313131;
  margin-bottom: 24px;
  cursor: pointer;
`;

/**
 *NOTE: Common Component
 * @description This Component will show Users Profile and Account creation Date.
 * @param {*} props
 * @return {*}
 */
const ProfileCard = (props) => {
  let { eachWorkSpace } = props;

  const handleWorkSpaceClick = (index) => {
    props.handleClickedWorkSpace(index);
  };

  return (
    <ProfileCardComponent
      onClick={() => handleWorkSpaceClick(eachWorkSpace.id)}
    >
      <Col xs={12} className="profileCardAvatarSkeleton">
        <Row>
          <Col xs={2} className="profileCardAvatar">
            <AccountNavigator
              iconText={eachWorkSpace.name.slice(0, 2).toUpperCase()}
              iconTextColor={"black"}
              iconTextSize={"sub-head-medium"}
            />
          </Col>
          <Col xs={10} className="profileCardContainer">
            <Col>
              <Typography
                text={eachWorkSpace.name}
                color={"#FAFAFA"}
                tag={"head-x-small"}
              />
            </Col>
          </Col>
        </Row>

        <Row className="profileCardSubTitle1">
          <Col xs={12}>
            <Typography
              text={`Created on ${moment
                .unix(eachWorkSpace.createdat)
                .format("DD MMM YY")}`}
              color={"#909090"}
              tag={"sub-head-small"}
            />
          </Col>
          {/* <Col xs={12} className="profileCardSubTitle2"> */}
          {/* <Typography
                  text={"No Collabrators"}
                  color={" #909090"}
                  tag={"sub-head-small"}
                /> */}
          {/* </Col> */}
        </Row>
      </Col>
    </ProfileCardComponent>
  );
};

export default ProfileCard;
