import { Col, Container, Row } from "reactstrap";
import BackButton from "../../components/common/_backButton";
import condenseLogo from "../../assets/images/condenseLogo.svg";
import supportImg from "../../assets/images/supportImg1.png";
import discord from "../../assets/images/discord.png";
import technicalDocs from "../../assets/images/technicalDocs.png";

import Typography from "../../components/common/_typography";
import Button from "../../components/common/_button";
import { useHistory, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";

const SupportPage = () => {
  const history = useHistory();
  const [isSupportTime, setIsSupportTime] = useState(false);

  const handleBackButtonClick = () => {
    history.push("/");
  };

  useEffect(() => {
    let currentHour = moment().hours();

    if (currentHour >= 10 && currentHour < 19) {
      setIsSupportTime(true);
    } else {
      setIsSupportTime(false);
    }
  });

  return (
    <Container fluid>
      <Row>
        <Col xs={12} className="supportScreenBackground">
          <Col xs={12} className="supportPageLandingImage">
            <Row>
              <Col xs={3} className="flexJustifyCenterSupportPage">
                <BackButton onClickEvent={handleBackButtonClick} />
              </Col>
              <Col xs={6} className="flexJustifyCenterSupportPage">
                <img
                  src={condenseLogo}
                  className="supportPageLogoStyle"
                  alt="Condense Logo"
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="supportPageContentStyle">
            <Row>
              <Col xs={6}>
                <Col xs={12} className="supportPageImgContent1">
                  <img src={supportImg} />
                </Col>
                <Col xs={12} className="supportTitleStyle">
                  <Typography
                    text={"Lost in the flow? Get in touch with us"}
                    tag={"head-small"}
                    color={"#FAFAFA"}
                  />
                </Col>
                <Col xs={12} className="supportTitleStyle">
                  <Typography
                    text={
                      "We would love to hear from you, and help you resolve your queries."
                    }
                    tag={"head-x-x-small"}
                    color={"#B7B7B7"}
                  />
                </Col>
                <Col xs={12} className="supportButtonStyles">
                  <Row>
                    <Col xs={6}>
                      <Button tag="secondary" width="301px" btnText="Call Us" />
                      <Row
                        className={
                          isSupportTime
                            ? "supportTimingMainRowStyle1"
                            : "supportTimingMainRowStyle2"
                        }
                      >
                        <Col xs={2} className="supportTimingDotColumn1">
                          <Row>
                            <Col className="supportTimingDotColumn2">
                              <div
                                className={
                                  isSupportTime
                                    ? "supportTimingDotTrue"
                                    : "supportTimingDotFalse"
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={10} className="supportTimingText">
                          <Typography
                            text={
                              isSupportTime
                                ? "Available Now"
                                : "Available 10 AM - 7 PM IST"
                            }
                            tag={"p-large"}
                            color={isSupportTime ? "#37D184" : "#EA3D4A"}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={6}>
                      <Button
                        tag="secondary"
                        width="301px"
                        btnText="Email Support"
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
              <Col xs={6}>
                <Row>
                  <Col xs={12} className="supportTechnicalDocImageStyle">
                    <img src={technicalDocs} alt="technicalDocs" />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="supportDiscordImageStyle">
                    <img src={discord} alt="discord" />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(SupportPage);
