import styled from "styled-components";
import { Col, Row } from "reactstrap";
import Typography from "./_typography";

const AccountNavigatorIcon = styled.div`
  width: 48px;
  border-radius: 100px;
  height: 48px;
  background: #fafafa;
`;

const AccountNavigator = (props) => {
  const { iconText, iconTextColor, iconTextSize } = props;

  return (
    <AccountNavigatorIcon>
      <Row>
        <Col style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'10px'}}>
          <Typography
            text={iconText}
            color={iconTextColor}
            tag={iconTextSize}
          />
        </Col>
      </Row>
    </AccountNavigatorIcon>
  );
};

export default AccountNavigator;
