import React, { useState, useContext, useEffect } from "react";

const SnackbarContext = React.createContext({
  message: "",
  showMessage: () => {},
});

const SnackbarProvider = ({ children }) => {
  const [message, setMessage] = useState("");

  const showMessage = (newMessage) => {
    setMessage(newMessage);
  };

  return (
    <SnackbarContext.Provider value={{ message, showMessage }}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider ,SnackbarContext};
