import { Col, Row, Container } from "reactstrap";
import Button from "../../components/common/_button";
import ProgressBar from "../../components/onBoardingComponents/_progressBar";
const OnboardingFooter = (props) => {
  const { isSourceSelected, isDeviceSelected } = props;

  return (
    <Row className="onBoardingFooterMainRow">
      <Col xs={12} className="onBoardingFooterSkeleton">
        <Col xs={12} className="onBoardingFooterStyle">
          {/* TODO: Need to implemented Progreebar */}
          {/* <Col xs={12} md={5} lg={3} className="onBoardingFooterProgressBar">
              <ProgressBar />
            </Col> */}
          <Col xs={12} md={12} lg={12} className="onBoardingFooterButtons">
            {props.showNextAndBackButton ? (
              <Row>
                <Col xs={6} md={6} lg={6}>
                  <Button
                    onClickEvent={props.onClickBack}
                    btnText={"Back"}
                    tag="secondary"
                  />
                </Col>
                <Col xs={6} md={6} lg={6}>
                  <Button
                    onClickEvent={props.onClickNext}
                    btnText={props.buttonTxt ? props.buttonTxt : "NEXT"}
                    tag="primary"
                    disabled={
                      props.buttonTxt ? !isDeviceSelected : !isSourceSelected
                    }
                  />
                </Col>
              </Row>
            ) : null}
          </Col>
        </Col>
      </Col>
    </Row>
  );
};
export default OnboardingFooter;
